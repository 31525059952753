import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from '@iconify/react';
import { Box, Card, Stack, TextField, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocales } from '../locales';
import { TEETH } from '../assets/data/teeth';
import EMPTY from '../assets/illustrations/illustration_empty_content.svg';
import LoadingScreen from '../components/loading-screen';
import { accpetCommand, getCommandById } from '../redux/slices/command/commandThunk';
import { dispatch, useSelector } from '../redux/store';
import ProtheseUserInfo from '../sections/@dashboard/patient/prothese/ProtheseUserInfo';
import { attachementStyle, emptySection } from '../sections/@dashboard/patient/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from '../routes/paths';
import { useAuthContext } from '../auth/useAuthContext';
import Mouth from '../components/mouth/Mouth';
export default function ConsultCommand() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { translate } = useLocales();
    const [descriptionState, setDescriptionState] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (id)
            dispatch(getCommandById({ id }));
    }, [id]);
    const [icons, setIcons] = useState([]);
    useEffect(() => {
        const loadIcons = async () => {
            const loadedIcons = await Promise.all(TEETH.map(async (tooth) => {
                return {
                    id: tooth?.id,
                    icon: await tooth?.icon,
                };
            }));
            setIcons(loadedIcons);
        };
        loadIcons();
    }, []);
    const { enqueueSnackbar } = useSnackbar();
    const data = useSelector((state) => state?.command);
    const confirmed = data?.oneData?.cartePatient?.prothese?.traitement?.dents.map((el) => {
        return Number(el?.name);
    });
    const dents = data?.oneData?.cartePatient?.prothese?.traitement?.dents;
    const attachments = data?.oneData?.cartePatient?.prothese?.attachments;
    const { user } = useAuthContext();
    return (_jsxs(Stack, { children: [(data?.error || !data?.oneData?.cartePatient) && (_jsxs(Box, { sx: emptySection, children: [_jsx("img", { src: EMPTY, alt: "error", style: { margin: '0 auto' } }), _jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Commande introuvable ou quelque chose c'est mal pass\u00E9" })] })), _jsx(_Fragment, { children: data?.loading ? (_jsx(LoadingScreen, {})) : (_jsx(_Fragment, { children: data?.oneData && !data?.error && data?.oneData?.cartePatient && (_jsxs(Box, { children: [!data?.oneData?.prothesist ? (_jsxs(Card, { sx: {
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px',
                                    justifyContent: 'space-between',
                                    padding: '10px 20px',
                                    height: 'fit-content',
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    alignItems: 'center',
                                }, children: [_jsxs(Box, { sx: {
                                            padding: '15px 30px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '40px',
                                            width: '100%',
                                            justifyContent: 'start',
                                        }, children: [_jsx(Box, { sx: {
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    gap: '40px',
                                                    justifyContent: 'center',
                                                }, children: _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', children: data?.oneData?.createdBy?.email }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.oneData?.createdBy?.phone })] }) }), _jsxs(Box, { sx: {
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    gap: '15px',
                                                    justifyContent: 'center',
                                                }, children: [data?.oneData?.startDate && (_jsx(DesktopDatePicker, { value: new Date(data?.oneData?.startDate), label: 'Date limite', readOnly: true })), data?.oneData?.endDate && (_jsx(DesktopDatePicker, { value: new Date(data?.oneData?.endDate), label: 'Date limite', readOnly: true }))] })] }), _jsxs(Box, { sx: {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: '15px',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }, children: [_jsx(Box, { sx: { display: 'flex', gap: '15px' }, children: _jsxs(Box, { sx: {
                                                        height: 'fit-content !important',
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '10px',
                                                        justifyContent: 'start',
                                                        padding: '10px 20px',
                                                    }, children: [_jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Ajouter des remarques suppl\u00E9mentaires :" }), _jsx(TextField, { sx: { marginTop: '10px' }, fullWidth: true, multiline: true, rows: 6, value: descriptionState, onChange: (e) => {
                                                                setDescriptionState(e.target.value);
                                                            }, label: 'Entrer Votre message ici' })] }) }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'row-reverse', gap: '10px' }, children: [_jsx(LoadingButton, { loading: loading, variant: "contained", sx: {
                                                            justifySelf: 'flex-end',
                                                            height: 'fit-content',
                                                            marginTop: '40px',
                                                        }, color: "success", onClick: () => {
                                                            setLoading(true);
                                                            const params = descriptionState
                                                                ? {
                                                                    description: descriptionState,
                                                                    status: 'received',
                                                                }
                                                                : { status: 'received' };
                                                            if (id) {
                                                                dispatch(accpetCommand({
                                                                    id: id,
                                                                    params,
                                                                })).then((res) => {
                                                                    setLoading(false);
                                                                    if (res?.payload?.statusCode === 200) {
                                                                        enqueueSnackbar('la commande est acceptée avec succès');
                                                                        navigate(PATH_DASHBOARD.orders);
                                                                    }
                                                                    else {
                                                                        enqueueSnackbar(res.payload?.message || "Quelque chose c'est mal passé !", {
                                                                            variant: 'error',
                                                                        });
                                                                    }
                                                                });
                                                            }
                                                        }, children: "Accepter" }), _jsx(LoadingButton, { loading: loading, variant: "contained", sx: {
                                                            justifySelf: 'flex-end',
                                                            height: 'fit-content',
                                                            marginTop: '40px',
                                                        }, color: "error", onClick: () => {
                                                            setLoading(true);
                                                            const params = descriptionState
                                                                ? {
                                                                    description: descriptionState,
                                                                    status: 'rejected',
                                                                }
                                                                : { status: 'rejected' };
                                                            if (id) {
                                                                dispatch(accpetCommand({
                                                                    id: id,
                                                                    params,
                                                                })).then((res) => {
                                                                    setLoading(false);
                                                                    if (res?.payload?.statusCode === 200) {
                                                                        enqueueSnackbar('la commande est acceptée avec succès');
                                                                        navigate(PATH_DASHBOARD.orders);
                                                                    }
                                                                    else {
                                                                        enqueueSnackbar(res.payload?.message || "Quelque chose c'est mal passé !", {
                                                                            variant: 'error',
                                                                        });
                                                                    }
                                                                });
                                                            }
                                                        }, children: "Refuser" })] })] })] })) : (_jsx(_Fragment, { children: data?.oneData?.prothesist?._id === user?._id ? (_jsx(Card, { sx: {
                                        width: '100%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        justifyContent: 'center',
                                        padding: '20px 40px',
                                        height: 'fit-content',
                                        marginTop: '15px',
                                        marginBottom: '15px',
                                        alignItems: 'center',
                                    }, children: _jsxs(Typography, { color: 'success', variant: "overline", sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                            color: 'green',
                                        }, children: [_jsx(Icon, { icon: "material-symbols:error", color: "green", width: '20px' }), "Vous avez d\u00E9j\u00E0 accept\u00E9e ou refus\u00E9e cette commande !"] }) })) : (_jsx(Card, { sx: {
                                        width: '100%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        justifyContent: 'center',
                                        padding: '20px 40px',
                                        height: 'fit-content',
                                        marginTop: '15px',
                                        marginBottom: '15px',
                                        alignItems: 'center',
                                    }, children: _jsxs(Typography, { color: 'error', variant: "overline", sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                        }, children: [_jsx(Icon, { icon: "material-symbols:error", color: "red", width: '20px' }), "Cette commande est d\u00E9j\u00E0 accept\u00E9e ou refus\u00E9e par un autre utilisateur !"] }) })) })), _jsx(ProtheseUserInfo, { data: data?.oneData?.cartePatient?.prothese?.traitement?.patient }), _jsxs(Box, { display: 'flex', gap: '15px', children: [_jsx(Box, { flex: '3', children: _jsx(Mouth, { confirmed: confirmed }) }), _jsx(Card, { sx: { marginTop: '15px', padding: '15px', width: '60%', flex: '7' }, children: _jsx(Box, { sx: {
                                                display: 'flex',
                                                justifyContent: 'start',
                                                flexDirection: 'column',
                                                gap: '20px',
                                            }, children: data?.oneData &&
                                                dents?.map((el, index) => {
                                                    return (_jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            justifyContent: 'start',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            borderRadius: '10px',
                                                        }, children: [_jsxs(Box, { sx: {
                                                                    display: 'flex',
                                                                    gap: '10px',
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    borderRadius: '10px',
                                                                    cursor: 'pointer',
                                                                    width: '100px !important',
                                                                }, children: [_jsx(Box, { sx: {
                                                                            padding: '10px 5px',
                                                                            borderRadius: '20px',
                                                                            width: '100px !important',
                                                                        }, children: _jsx("img", { src: icons[Number(el?.name) - 1]?.icon, alt: "error", style: { margin: '0 auto' } }) }), _jsx(Typography, { variant: "h6", color: 'text.secondary', children: icons[Number(el?.name) - 1]?.id })] }), _jsxs(Box, { children: [_jsxs(Typography, { variant: "h6", color: 'text.secondary', sx: { display: 'flex', alignItems: 'center', gap: '5px' }, children: [_jsx(Box, { sx: {
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: el?.color,
                                                                                    border: '0.5px solid lightgray',
                                                                                } }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: el?.type?.code })] }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: el?.remarks })] })] }, index));
                                                }) }) })] }), _jsx(Card, { sx: attachementStyle, children: attachments?.length > 0 &&
                                    attachments?.map((el, key) => {
                                        return (_jsx(Box, { children: _jsxs(Stack, { spacing: 2, direction: "row", alignItems: "center", sx: {
                                                    my: 1,
                                                    px: 1,
                                                    py: 0.75,
                                                    borderRadius: 0.75,
                                                    border: (theme) => `solid 1px ${theme?.palette?.divider}`,
                                                    flexWrap: 'wrap',
                                                }, children: [_jsx("a", { download: true, onClick: (e) => {
                                                            e.preventDefault();
                                                            saveAs(el?.path);
                                                        }, style: { cursor: 'pointer' }, children: _jsx(Icon, { icon: "material-symbols:download", color: "black", width: '20px' }) }), _jsx(Stack, { flexGrow: 1, sx: { minWidth: 0 }, children: _jsx(Typography, { variant: "subtitle2", noWrap: true, children: el?.path?.split('/uploads/')[1] }) })] }, key) }));
                                    }) }), data?.oneData?.description && (_jsx(Card, { sx: {
                                    marginTop: '15px',
                                    padding: '15px',
                                    display: 'flex',
                                    aligntItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }, children: _jsxs(Typography, { color: 'warning', variant: "subtitle1", sx: {
                                        display: 'flex',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        gap: '5px',
                                    }, children: [_jsx(Icon, { icon: "material-symbols:error", color: "orange", width: 20, height: 20 }), data?.oneData?.description] }) }))] })) })) })] }));
}
